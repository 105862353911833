.sidebar-main {
  z-index: 2;
  overflow: auto !important;
}

.sidebar-main img {
  width: 20px;
  margin-right: 15px;
}

.top-menu-link {
  cursor: pointer;
  margin-left: 35px;
}

.menu-badge {
  position: absolute;
  left: 10px;
}

.ant-layout-header {
  box-shadow: 0 0 6px #c6c6c6;
}

.ant-layout-sider {
  position: fixed;
  height: 100vh;
  overflow: hidden;
  background-color: #2d2e30;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ant-menu {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  background-color: #2d2e30 !important;
}

.ant-menu-dark .ant-menu-item {
  color: rgba(255, 255, 255, 0.85) !important;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-selected {
  color: rgba(255, 255, 255, 1) !important;
}

.ant-menu-item span {
  font-weight: bold;
}

.ant-menu-item .anticon {
  font-size: 18px !important;
  float: left !important;
  margin-top: 10px !important;
  margin-right: 17px !important;
}

.ant-menu-item {
  transition: none !important;
}

.ant-menu-item-selected {
  background-color: #32a9e1 !important;
}

.logo {
  height: 35px;
  display: inline-block;
}

.logo img {
  width: 150px;
  height: 35px;
}

.user-nav-active {
  color: #222d32;
}

.user-nav-content {
  min-width: 250px;
}

.user-nav-content ul li {
  transition: 0.5s ease;
}

.user-nav-details {
  display: flex;
  align-items: center;
  padding: 15px 0px 15px 20px;

  border-bottom: 1px solid #e8e8e8;
}

.user-nav-details .ui-profile-icon {
  margin-right: 10px;
}

.popover-workspace-switch {
  min-width: 175px;
}

.popover-workspace-switch li:last-of-type {
  border-top: 1px solid #e8e8e8;
}

.workspace-switch-disabled {
  cursor: default !important;
}

.workspace-switch-icon {
  position: absolute;
  transform: translateX(-14px);
  -ms-transform: translateX(-14px);
  -moz-transform: translateX(-14px);
  -webkit-transform: translateX(-14px);
}

.workspace-switch-icon svg {
  color: #52c41a;
  width: 12px;
}

.popover-user-nav {
  position: fixed;
}

.popover-user-nav .ant-popover-inner-content {
  padding: 0;
}

.popover-user-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.padding-right-popover li {
  padding-right: 20px !important;
}

.popover-user-nav-manage {
  padding-right: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popover-user-nav ul li {
  cursor: pointer;
  color: inherit;
  display: flex;
  align-items: center;
  padding: 10px 0px 10px 20px;
}

.popover-user-nav ul li:hover {
  background: #f6f6f6;
}

.popover-user-nav ul li i {
  margin-right: 10px;
}

.logout-option-btn {
  padding: 15px 5px 15px 20px !important;
  border-top: 1px solid #e8e8e8;
  color: #c4302b !important;
}

.sidebar-top-menu {
  z-index: 99;
  display: flex;
  padding: 0px 22px;
  align-items: center;
  justify-content: space-between;

  width: 100vw;
  height: 64px;
  position: fixed;
  overflow-x: hidden;
  background: #0a0f16;
}

.ant-menu-item-selected {
  background-color: #32a9e1 !important;
}

.top-menu-user {
  display: flex;
  align-items: center;
}

.top-menu-user-option {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.85);
  font-size: 35px;
  margin-right: 15px;

  transition: 0.5s ease;
}

.top-menu-user-option .ui-profile-icon {
  margin-right: 10px;
}

.top-menu-user-details {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  font-size: 15px;
  margin-right: 15px;
}


.menu-burger {
  display: none;
}

@media screen and (max-height: 600px) {
  .ant-layout-sider-children {
    margin-bottom: 120px;
  }

  .sidebar-main {
    overflow: visible;
  }
}

@media screen and (max-width: 1000px) {
  .menu-burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    outline: none;
    cursor: pointer;
    width: 25px;
    height: 15px;
  }
  
  .menu-burger div {
    opacity: 1;
    width: 100%;
    height: 5%;
    background-color: white;
    border-radius: 25px;
  
    transform-origin: left;
    transition: all .5s ease;
  }
  
  .menu-burger-active div:nth-of-type(2) {
    transform: translateX(-50%);
    opacity: 0;
  }
  
  .menu-burger-active div:nth-of-type(1) {
    transform: rotate(45deg);
  }
  
  .menu-burger-active div:nth-of-type(3) {
    transform: translate(0px, 4px) rotate(-45deg);
  }

  .sidebar-main {
    left: -100%;
  }

  .sidebar-main-active {
    left: 0 !important;
  }

  .top-menu-user {
    background-color: #2d2e30;
    transition: 0.5s ease;
    position: fixed;
    flex-wrap: wrap;
    max-width: 250px;
    padding-left: 15px;
    padding-bottom: 15px;
    bottom: -100%;
    left: 0px;
  }

  .top-menu-user-active {
    bottom: 0 !important;
  }

  .top-menu-user-option {
    max-width: 250px;
    justify-content: flex-start;
  }

  .top-menu-link {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 15px;
  }
}
