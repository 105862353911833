/* CHANGE NAME */

.workspace-form {
  width: 100%;
  max-width: 700px;
  padding-bottom: 15px;
}

.workspace-form .ant-form-item {
  margin-bottom: 25px;
}

.workspace-form .ant-form-item-with-help {
  margin-bottom: 6px;
}

.workspace-form .ant-form-item:last-child {
  margin-bottom: 0;
}

.workspace-form .ant-form-item-label {
  text-align: left;
  min-width: 150px;
}

.workspace-form .ant-input-lg {
  padding: 5px 11px 4px !important;
  width: 400px;
  height: 36px;
  font-size: 14px;
}

.workspace-form .ant-form-item-label {
  text-align: left;
}

.workspace-form label i {
  margin-left: 8px;
}

.workspace-form-help {
  font-size: 13px;
  max-width: 300px;
}

.workspace-form-help ul {
  list-style: none;
  margin: 5px 0 0;
  padding: 0;
}

.workspace-form-help ul li {
  padding: 2px 3px;
}

/* MEMBERS */

.members-table {
  margin-top: 20px;
  overflow: hidden;
}

.members-table .email {
  min-width: 150px;
}

.members-table .role {
  min-width: 160px;
}

.members-table .role-label {
  padding-left: 11px;
}

.members-table .role .ant-select {
  width: 150px;
}

.members-table .actions {
  cursor: pointer;
  width: 120px;
}

.members-table .ant-table {
  overflow-x: auto;
}

/* INVITE */

.ant-alert {
  max-width: 700px;
}

.workspace-invite button {
  margin-left: 16px
}

.workspace-invite-label {
  display: inline-block;
  width: 150px;
  color: rgba(0, 0, 0, 0.85);
}

.workspace-invite-label i {
  margin-left: 8px;
}

.workspace-invite-emails {
  border-bottom: 1px solid #D9D9D9;
  width: 400px;
  padding: 5px;
  cursor: text;
  display: inline-block;
  transition: all 0.3s;
}

.workspace-invite-emails:hover {
  border-color: #188fffa6
}

.workspace-invite-hidden {
  width: auto;
  display: inline-block;
  visibility: hidden;
  position: fixed;
  overflow: auto;
}

.workspace-invite-emails input {
  border: none;
  outline: none;
  background-color: transparent;
}

.workspace-invite-emails-focus {
  border-color: #188fffa6 !important;
  box-shadow: 0px 0px 2px 1px #188fff6b;
}

.workspace-invite-emails-error {
  border-color: #f53653ce !important;
  box-shadow: 0px 0px 2px 1px #f536546b;
}

.workspace-invite-emails-placeholder {
  margin-top: 2px;
  margin-left: 5px;
  position: absolute;
  color: #bbbbbb;
}

.workspace-invite-error {
  margin-left: 150px;
  color: #f53653;
}

/* API KEYS */
.api-keys-settings {
  margin: 5px 0px 5px 0px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.api-keys-new-key {
  padding-bottom: 15px;
  margin-bottom: 15px;
  text-align: center;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.15);
}

.api-keys-new-key p {
  margin-top: 15px;
}

.api-keys-new-key button {
  margin-right: 10px;
}

/* DANGER ZONE */

.danger-zone-item {
  padding-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.danger-zone .danger-zone-item:last-of-type {
  border-bottom: none;
}
