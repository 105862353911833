@import '~antd/dist/antd.css';

html, body {
  margin: 0;
  padding: 0;

  background-color: #e6e7eb !important;

  font-family: "DIN-2014", Arial, Helvetica, sans-serif !important;
}

code {
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 13px !important;
}

.invisible-element {
  left: -9999px;
  opacity: 0;
  position: absolute;
}

.center-text {
  text-align: center;
}

.center-page {
  width: calc(100% - 250px);
  display: flex;
  justify-content: center;
}

.page-content {
  min-width: 800px;
  max-width: 1700px;
  padding: 20px 25px 25px;
  margin-left: 250px;
}

.ant-empty {
  padding-top: 80px;
  padding-bottom: 80px;
}

.ant-empty,
.ant-table-placeholder {
  color:rgba(0, 0, 0, 0.85) !important;
}

.button-go-back {
  position: absolute;
  width: 34px;
  height: 34px;
  top: 22px;
  right: 22px;
  text-align: center;
  font-size: 24px;
  border-radius: 50%;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0);
  transition: all .3s ease;
}

.button-go-back:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.detections-content {
  margin-left: 250px;
}

.no-workspace-wrapper {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-workspace-box {
  background: #ffffff;
  box-shadow: 0 2px 8px #e0dede;
  margin-top: 50px;

  width: 400px;
}

.no-workspace-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.no-workspace-box ul li {
  padding: 20px 0px 20px 20px;
  cursor: pointer;
}

.no-workspace-box ul li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.no-workspace-box ul li:last-of-type {
  border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.no-workspace-box ul li i {
  margin-right: 10px;
}

.no-workspace-box-title {
  width: 100%;
  padding: 20px 20px 10px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}

.alert-top-page {
  width: 100%;
  max-width: 100vw !important;
}

.alert-demo {
  border-radius: 0 !important;
  margin-left: 250px !important;
  width: calc(100% - 250px) !important;
}

.alert-fixed {
  z-index: 999;
  position: fixed !important;
}

.content-box {
  background: #ffffff;
  border-radius: 7.5px;
  box-shadow: 2.5px 2.5px 5px rgb(53 57 58 / 25%);
  padding: 20px 25px 25px;
  margin-top: 25px;

  min-width: 400px;
}

.content-box-raw {
  background: #ffffff;
  border-radius: 7.5px;
  box-shadow: 2.5px 2.5px 5px rgb(53 57 58 / 25%);
}

.ant-alert:not(.alert-demo) {
  border-radius: 7.5px !important;
}

.ant-input, .ant-select div {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
}

.content-box:first-child {
  margin-top: 0;
}

.page-images-col {
  padding-left: 15px !important;
  margin-top: 25px;
}

.page-images-col img {
  margin-bottom: 25px;
}

input[type="password"] {
  font: large Verdana, sans-serif;
  letter-spacing: 1px;
}

.margin-top-licensing {
  margin-top: 30px;
}

.margin-top-licensing b {
  margin-right: 10px;
}

.min-height-normal {
  min-height: 300px;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #e6e7eb !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
  border-color: #e6e7eb !important;
}

.ant-steps-item-finish .ant-steps-icon {
  color: #fff !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff !important;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #32a9e1 !important;
}

.ant-steps-item-wait .ant-steps-icon {
  color: #32a9e1 !important;
}

.ant-steps-item-tail::after {
  background-color: #32a9e1 !important;
}

.ant-steps-item-wait .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-steps-item-wait .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.65) !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #32a9e1 !important;
  background-color: #32a9e1 !important;
}

.tabs-transparent .ant-tabs-tab {
  background-color: transparent !important;
}

.box-tabs .ant-tabs-tab {
  font-size: 1.1em;
  margin: 0 !important;
  border: 0 !important;
  color: black !important;
  padding-top: 2px !important;
}

.box-tabs .ant-tabs-tab:not(.ant-tabs-tab-active) {
  border-radius: 0 !important;
  background-color: transparent !important;
}

.box-tabs .ant-tabs-top-bar .ant-tabs-nav {
  background-color: #edeef1;
  border-radius: 7.5px 7.5px 0 0 !important;
}

.box-tabs .ant-tabs-content {
  min-width: 400px;
  background: #ffffff;
  border-radius: 0 7.5px 7.5px 7.5px;
  padding: 20px 25px 25px;

  /* box-shadow: 2.5px 2.5px 5px rgb(53 57 58 / 25%); */
}

.box-tabs .ant-tabs-bar {
  margin-bottom: 0 !important;
}

.ant-table-small {
  border: 0 !important;
}

.ant-table-placeholder {
  background-color: #f2f3f5 !important;
}

.ant-table-small .ant-table-body {
  margin: 0 !important;
}

.ant-table-small .ant-table-tbody > tr > td {
  border-bottom: 0 !important;
}

.ant-table-small th {
  font-weight: bold !important;
}

.ant-table-small thead tr,
.ant-table-small thead tr:hover {
  background-color: #e6e7eb;
}

.ant-table-small .ant-table-expanded-row,
.ant-table-small .ant-table-expanded-row:hover {
  background-color: #e6e7eb;
}

.ant-table-row-expand-icon {
  display: flex !important;
  padding: 1px 0 0 0 !important;
  align-items: center !important;
  justify-content: center !important;
}

.ant-table-expand-row-even {
  background-color: #f2f3f5 !important;
}

.ant-table-expand-row-uneven {
  background-color: #fafafb !important;
}

.ant-table-small tbody .ant-table-row-level-0 {
  background-color: #fafafb;
}

.ant-table-small tbody .ant-table-row-level-0:nth-child(2n+1) {
  background-color: #f2f3f5;
}

.ant-btn-danger:not(:disabled) {
  color: #f15922 !important;
  border-color: #f15922 !important;
  background-color: transparent !important;
}

.ant-btn-danger:hover {
  color: white !important;
  border-color: #f15922 !important;
  background-color: #f15922 !important;
}

.ant-btn-primary:not(:disabled) {
  border-color: #32a9e1 !important;
  background-color: #32a9e1 !important;
}

.ant-btn-primary:hover {
  color: #32a9e1 !important;
  background-color: transparent !important;
}

.btn-seaerches-toggle {
  position: fixed !important;
  bottom: 10px;
  right: 10px;
  z-index: 100; 
  display: none !important;
}

@media screen and (max-width: 1000px) {
  .alert-demo {
    padding: 0 !important;
    margin-left: 0px !important;
    width: 100% !important;
  }

  .btn-seaerches-toggle {
    display: inline-block !important;
  }

  .page-content,
  .detections-content {
    margin-left: 0;
  }
}

@media all and (max-width: 768px) {
  .ant-pagination li {
      margin-bottom: 5px;
  }
}

@font-face {
  font-family: 'DIN-2014';
  src: local('DIN 2014 Light Italic'),
       url('media/fonts/DIN2014/DIN2014-LightItalic.woff'),
       format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'DIN-2014';
  src: local('DIN 2014 Light'),
       url('media/fonts/DIN2014/DIN2014-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-2014';
  src: local('DIN 2014 Regular'),
      url('media/fonts/DIN2014/DIN2014-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-2014';
  src: local('DIN 2014 Italic'),
       url('media/fonts/DIN2014/DIN2014-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'DIN-2014';
  src: local('DIN 2014 Bold'),
       url('media/fonts/DIN2014/DIN2014-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'DIN-2014';
  src: local('DIN 2014 Bold Italic'),
       url('media/fonts/DIN2014/DIN2014-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'DIN 2014';
  src: local('DIN 2014 DemiBold'),
       url('media/fonts/DIN2014/DIN2014-DemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
